<template>
  <div class="room-pic">
    <el-button type="text" size="medium" @click="onPreview">查看图片</el-button>
    <el-image class="imgs" ref="preview" :src="url" :preview-src-list="srcList"></el-image>
  </div>
</template>
 
<script>
import {
  getRoomInfo,
} from "@/api/resource/room"
export default {
  props: ['roomId'],
  data() {
    return {
      url: "",
      srcList: [],
    };
  },
  methods: {
    // 【请求】房间详情
    getRoomInfo() {
      let data = {
        roomId: this.roomId
      };
      getRoomInfo(data).then(res => {
        if (res.isSuccess == "yes") {
          if (res.data.fileList) {
            let list = res.data.fileList
            for (let i of list) {
              this.srcList = [...this.srcList, i.url]
            }
            this.$nextTick(() => {
              this.$refs.preview.clickHandler();
            });
          } else {
            this.$message({
              message: "暂未上传图片",
              type: "success",
            });
          }
        }
      })
    },

    // 【监听】房源详情
    onPreview() {
      this.getRoomInfo();
    },
  },
};
</script>
 
<style lang="scss" scoped>
.room-pic {
  display: inline-flex;
  position: relative;
}

.imgs {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
}
</style>