<template>
  <div class="high-price">
    <el-button type="text" size="medium" @click="onTable">高级定价</el-button>
    <!-- 列表弹框 -->
    <el-dialog title="高级定价列表" :visible.sync="tableShow" :close-on-click-modal="false" append-to-body width="40%">
      <div class="add-btn">
        <el-button type="primary" @click="onAdd()">新增时段定价</el-button>
      </div>
      <!-- 弹框表格 -->
      <el-table :data="tableData" border stripe :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }">
        <el-table-column label="时段" align="center"  min-width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.timeSlotStart }}</span>
            <span>~</span>
            <span>{{ scope.row.timeSlotEnd }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格" align="center" >
          <template slot-scope="scope">
            <span>{{ scope.row.roomPrice || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="周末价格" align="center" >
          <template slot-scope="scope">
            <span>{{ scope.row.weekendPrice || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="节假日价格" align="center" min-width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.holidayPrice || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="medium" @click.stop="onDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <!-- 起始时间 -->
          <el-form-item label="起始时间" label-width="120px" prop="timeSlotStart">
            <el-time-select placeholder="起始时间" v-model="params.timeSlotStart" :editable="false" :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30',
              maxTime: params.timeSlotEnd
            }">
            </el-time-select>
          </el-form-item>
          <!-- 结束时间 -->
          <el-form-item label="结束时间" label-width="120px" prop="timeSlotEnd">
            <el-time-select placeholder="结束时间" v-model="params.timeSlotEnd" :editable="false" :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30',
            }">
            </el-time-select>
          </el-form-item>
          <!-- 房间价格 -->
          <el-form-item label="房间价格" label-width="120px" prop="roomPrice">
            <el-input v-model="params.roomPrice" autocomplete="off" maxlength="8" placeholder="请填写房间价格">
              <template slot="append">元/月</template>
            </el-input>
          </el-form-item>
          <!-- 周末价格 -->
          <el-form-item label="周末价格" label-width="120px" prop="weekendPrice" style="margin-bottom: 0;">
            <el-input v-model="params.weekendPrice" autocomplete="off" maxlength="8" placeholder="请填写周末价格">
              <template slot="append">元/月</template>
            </el-input>
          </el-form-item>
          <p class="tips">Tips:不填即不设置周末价格,按以上平时价格执行</p>
          <!-- 节假日价格 -->
          <el-form-item label="节假日价格" label-width="120px" prop="holidayPrice" style="margin-bottom: 0;">
            <el-input v-model="params.holidayPrice" autocomplete="off" maxlength="8" placeholder="请填写节假日价格">
              <template slot="append">元/月</template>
            </el-input>
          </el-form-item>
          <p class="tips">Tips:不填即不设置节假日价格,按以上周末和平时价格执行</p>
          <p class="tips">与周末重合优先使用节假日价格</p>
        </section>
        <section class="form-foot">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getRoomPriceList, getRoomPriceAdd, getRoomPriceEdit, getRoomPriceDel } from "@/api/resource/room";
import { rules } from "@/db/rules";
export default {
  props: ['roomId'],
  data() {
    return {
      tableShow: false, //弹框开关
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      tableData: [],//列表数据
      rules,
      params: {
        roomId: this.roomId,
        timeSlotStart: "",//开始时间
        timeSlotEnd: "",//结束时间
        roomPrice: "",//房间价格
        weekendPrice: "",//周末价格
        holidayPrice: "",//节假日价格
      },
    }
  },
  computed: {
    minTime() {
      let obj = this.timeObj;
      obj.minTime = this.params.timeSlotStart
      return obj
    }
  },
  methods: {
    // 【请求】高级定价列表
    getRoomPriceList() {
      let data = {
        roomId: this.roomId
      };
      getRoomPriceList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
        }
      })
    },

    // 【请求】高级定价新增
    getRoomPriceAdd() {
      let data = this.params;
      getRoomPriceAdd(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getRoomPriceList();
        }
      })
    },

    // 【请求】高级定价编辑
    getRoomPriceEdit() {
      let data = this.params;
      getRoomPriceEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getRoomPriceList();
        }
      })
    },


    // 【监听】打开列表
    onTable() {
      this.tableShow = true;
      this.getRoomPriceList();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增时段价格";
      this.params = {
        roomId: this.roomId,
        timeSlotStart: "",//开始时间
        timeSlotEnd: "",//结束时间
        roomPrice: "",//房间价格
        weekendPrice: "",//周末价格
        holidayPrice: "",//节假日价格
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑时段价格";
      this.params = JSON.parse(JSON.stringify(row));
      this.timeToTime = [this.params.timeSlotStart, this.params.timeSlotEnd];
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.id) {
            this.getRoomPriceEdit();
          } else {
            this.getRoomPriceAdd();
          }
        } else {
          return false;
        }
      });
    },

    // 【监听】删除
    onDel(row) {
      let data = {
        roomId: row.roomId,
        id: row.id
      }
      this.$confirm('你确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getRoomPriceDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getRoomPriceList();
          }
        })
      });
    },


  }
}
</script>

<style lang="scss" scoped>
.add-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: .15rem;
}

.form-foot {
  display: flex;
  justify-content: flex-end;
}

.tips {
  color: #999;
  font-size: .12rem;
  display: flex;
  margin: .1rem 0 .1rem 1.2rem
}
</style>