<template>
  <div class="room-list">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.serviceId"
          placeholder="请选择服务内容"
          @change="reload"
          clearable
        >
          <el-option
            v-for="item in serviceList"
            :key="item.serviceId"
            :label="item.serviceName"
            :value="item.serviceId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onExport(3)">点单码导出</el-button>
        <el-button type="primary" @click="onExport(1)">位置码导出</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.topNo || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务内容" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="位置名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.roomName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="价格" align="center">
        <template slot-scope="scope">
        <!-- 1,"元/小时"2 ,"元/天  3 ,"元/月" -->
          <span>{{ (scope.row.price?('￥'+scope.row.price+priceUnit[scope.row.pricingType-1]):'-')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="价格描述" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.priceDescribe || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="优惠信息" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.discountInformation || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="标签" min-width="100">
        <template slot-scope="scope">
          <el-tag
            class="tag-item"
            v-for="(item, index) in tagList(scope.row.label)"
            :key="index"
            >{{ item }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="位置图片数量" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.pictureNum || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <RoomPic
            :roomId="scope.row.roomId"
            v-if="scope.row.pictureNum"
          ></RoomPic>
          <RoomEditBtn
            :roomId="scope.row.roomId"
            @reload="editReload"
          ></RoomEditBtn>
          <el-button type="text" size="medium" @click="goOpenArrange(scope.row)"
            >排序</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!-- 导出进度弹框 -->
    <el-dialog
      :title="exportTitle"
      :visible.sync="exportShow"
      center
      :close-on-click-modal="false"
      append-to-body
      width="20%"
    >
      <div class="export">
        <el-progress
          class="mb15 export-success"
          type="circle"
          text-color="#13CE66"
          :percentage="100"
          status="success"
          v-if="progressInfo.complete"
        ></el-progress>
        <el-progress
          class="mb15"
          type="circle"
          color="#EF9807"
          :percentage="percentage"
          :format="format"
          v-else
        ></el-progress>
        <p class="mb15">
          <b style="color: #13ce66" v-if="progressInfo.complete">已完成</b>
          <b style="color: #ef9807" v-else>进行中</b>
        </p>
        <el-button v-if="this.downloadUrl" @click="onDown">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRoomList,
  getServiceList,
  getMiniDeal,
  getMiniProgress,
  getDownloadUrl,
  getTableSort,
} from "@/api/resource/room";
import { rules } from "@/db/rules";
import { ObjToOpt } from "@/utils/utils";
import RoomEditBtn from "./child/RoomEditBtn.vue";
import RoomPic from "./child/RoomPic.vue";
export default {
  components: { RoomEditBtn, RoomPic },
  data() {
    return {
      rules,
      ObjToOpt,
      serviceList: [],
      // table列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      priceUnit:['元/小时','元/天','元/月'],
      tableParams: {
        page: 1,
        size: 10,
        shopId: "",
        serviceId: "",
      },
      // 位置码导出
      exportShow: false,
      percentage: 0,
      progressInfo: {
        complete: false,
        num: 0,
        total: 0,
      },
      downloadUrl: "",
      exportTitle: "",
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getRoomList(); // 【请求】房间列表
    this.getServiceList(); // 【请求】服务列表
  },
  methods: {
    // 打开排序弹框
    goOpenArrange(row) {
      this.$prompt("请填写序号", "排序", {
        confirmButtonText: "确定",
        inputPattern: /^[0-9]\d*$/,
        inputErrorMessage: "请填写正整数",
        showCancelButton: false, //取消按钮
        closeOnClickModal: false, //点击遮罩
        inputValue: row.sortNum, //初始值
      }).then(({ value }) => {
        let data = {
          topNo: value,
          roomId: row.roomId, //商户ID
        };
        this.getTableSort(data);
      });
    },
    // 【请求】表格排序
    getTableSort(data) {
      getTableSort(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getRoomList();
        }
      });
    },
    format() {
      return `${this.progressInfo.num}/${this.progressInfo.total}`;
    },

    // 【转换】标签
    tagList(row) {
      if (row) {
        return row.split(",");
      } else {
        return [];
      }
    },

    // 【请求】房间列表
    getRoomList() {
      let data = this.tableParams;
      getRoomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】服务列表
    getServiceList() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      });
    },

    // 【请求】处理位置码
    getMiniDeal(val) {
      let data = {
        shopId: this.tableParams.shopId,
        serviceTemplateType: val,
      };
      console.log(1111111, data);
      getMiniDeal(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message(res.data);
          this.getMiniProgress(val); //获取处理进度
        }
      });
    },

    // 【请求】获取处理进度
    getMiniProgress(val) {
      let data = {
        shopId: this.tableParams.shopId,
        serviceTemplateType: val,
      };
      getMiniProgress(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.progressInfo = res.data;
          this.percentage = Math.round((res.data.num / res.data.total) * 100);
          if (res.data.complete) {
            this.getDownloadUrl(val);
          } else {
            setTimeout(() => {
              this.getMiniProgress(val);
            }, 1000);
          }
        }
      });
    },

    // 【请求】获取下载链接
    getDownloadUrl(val) {
      let data = {
        shopId: this.tableParams.shopId,
        serviceTemplateType: val,
      };
      getDownloadUrl(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.downloadUrl = res.data;
        }
      });
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getRoomList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getRoomList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】编辑
    editReload() {
      this.getRoomList();
    },

    // 【监听】位置码导出
    onExport(val) {
      if (val == 3) {
        this.exportTitle = "点单码导出";
      } else {
        this.exportTitle = "位置码导出";
      }
      this.exportShow = true;
      this.percentage = 0;
      this.progressInfo = {
        complete: false,
        num: 0,
        total: 0,
      };
      this.downloadUrl = "";
      this.getMiniDeal(val); //处理位置码
    },

    // 【监听】位置码下载
    onDown() {
      window.location.href = this.downloadUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.room-list {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.tag-item {
  margin: 0 0.05rem 0.05rem 0;
}

.export {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.export-success {
  .el-icon-check {
    font-size: 0.48rem;
  }
}
</style>